<!--
 * @Author: wang peng
 * @Description: 占位费列表  
-->
<template>
  <scroll-layout class="directConnectionPile-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'order:paceholder:column'" type="plain" @click="customColumns">
              自定义列
            </ykc-button>
            <ykc-button v-rbac="'order:paceholder:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
      <!--自定义列弹窗 -->
      <ykc-operation-dialog
        title="自定义列"
        show-footer
        :show.sync="show1"
        :before-close="onCLose"
        :before-cancel="onCLose"
        :before-confirm="beforeConfirm">
        <div slot="content">
          <el-form
            size="mini"
            label-width="80px"
            ref="customForm"
            class="customForm"
            :model="customForm"
            :rules="customRules">
            <el-form-item label="列选项" prop="checkOption">
              <ykc-checkbox
                :configSet="{
                  label: 'label',
                  value: 'value',
                }"
                :data="customData"
                v-model="customForm.checkOption"
                @change="checkboxChange"></ykc-checkbox>
            </el-form-item>
          </el-form>
        </div>
      </ykc-operation-dialog>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { orderPage } from '@/service/apis';
  import { code, getLastTime, localSetItem, localGetItem, offlineExport } from '@/utils';

  export default {
    name: 'placeholderOrderList',
    inject: ['reload'],
    data() {
      return {
        show1: false,
        searchParams: {
          date: [],
          occupancyOrderNo: '',
          chargingOrderNo: '',
          stationName: '',
          gunName: '',
          companyName: '',
          userAccount: '',
          plateNumber: '',
          occEndEndTime: '',
          occEndStartTime: '',
          orderStatus: '',
          count: true,
          occupancyOrderIds: [],
        },
        customForm: {
          checkOption: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        },
        customData: [
          {
            value: 0,
            label: '占位单号',
          },
          {
            value: 1,
            label: '充电单号',
          },
          {
            value: 2,
            label: '充电用户',
          },
          {
            value: 3,
            label: '归属机构',
          },
          {
            value: 4,
            label: '车牌号码',
          },
          {
            value: 5,
            label: '充电站',
          },
          {
            value: 6,
            label: '计费时长',
          },
          {
            value: 7,
            label: '订单金额',
          },
          {
            value: 8,
            label: '应收',
          },
          {
            value: 9,
            label: '实付',
          },
          {
            value: 10,
            label: '终端名称',
          },
          {
            value: 11,
            label: '计费开始时间',
          },
          {
            value: 12,
            label: '计费结束时间',
          },
          {
            value: 13,
            label: '减免时长',
          },
          {
            value: 14,
            label: '订单状态',
          },
        ],
        customRules: {
          checkOption: [{ required: true, message: '请至少选择一个列名称', trigger: 'blur' }],
        },
        tableTitle: '订单列表',
        tableData: [],
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '占位单号', prop: 'occupancyOrderNo', width: '200px', id: 0 },
          { label: '充电单号', prop: 'chargingOrderNo', width: '200px', id: 1 },
          {
            label: '充电用户',
            prop: 'userAccount',
            minWidth: '200px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <span>
                    {row.userAccount}({row.nickName})
                  </span>
                );
              },
            },
            id: 2,
          },
          { label: '归属机构', prop: 'companyName', minWidth: '150px', id: 3 },
          { label: '车牌号码', prop: 'plateNumber', minWidth: '150px', id: 4 },
          { label: '充电站', prop: 'stationName', minWidth: '150px', id: 5 },
          { label: '计费时长(min)', prop: 'occupancyTime', minWidth: '160px', id: 6 },
          {
            label: '订单金额(元)',
            prop: 'occupancyFee1',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return <span>{row.occupancyFee}</span>;
              },
            },
            id: 7,
          },
          {
            label: '应收(元)',
            prop: 'occupancyFee',
            minWidth: '150px',

            id: 8,
          },
          {
            label: '实付(元)',
            prop: 'occupancyActualFee',
            minWidth: '150px',
            id: 9,
          },
          { label: '终端名称', prop: 'gunName', minWidth: '200px', id: 10 },
          { label: '计费开始时间', prop: 'startTime', minWidth: '200px', id: 11 },
          { label: '计费结束时间', prop: 'endTime', minWidth: '200px', id: 12 },
          { label: '减免时长（min）', prop: 'reductionTime', minWidth: '150px', id: 13 },
          { label: '订单状态', prop: 'orderStatus', minWidth: '150px', id: 14 },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    created() {
      // 页面跳转传参
      if (this.$route.query.data) {
        const query = JSON.parse(this.$route.query.data);
        console.log('query+++', query);

        if (query.occupancyOrderIds && query.occupancyOrderIds !== null) {
          this.searchParams.occupancyOrderIds = query.occupancyOrderIds;
        }

        if (query.recordIds && query.recordIds !== null) {
          this.searchParams.occupancyOrderIds = query.recordIds;
        }
      }

      // 近30天初始化时间
      if (this.searchParams.occupancyOrderIds !== null) {
        this.searchParams.date = [];
        this.searchParams.occEndEndTime = '';
        this.searchParams.occEndEndTime = '';
      } else {
        const startDate = getLastTime('yyyy-MM-dd', 30);
        const endDate = getLastTime('yyyy-MM-dd', 0);
        this.searchParams.date = [startDate, endDate];
        this.searchParams.occEndEndTime = startDate;
        this.searchParams.occEndEndTime = endDate;
      }

      const customForm = localGetItem('placeholderOrderCustomForm');

      if (customForm) {
        this.customForm.checkOption = customForm;
        this.tableColumns = this.tableColumns.filter(item => {
          return customForm.includes(item.id);
        });
      } else {
        this.tableColumns = this.tableColumns.filter(item => {
          return ![10, 11, 12, 13, 14].includes(item.id);
        });
      }

      this.requestList();
      this.initData();
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcDatePicker',
            key: 'date',
            label: '开始充电',
            placeholder: '请选择充电时间',
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '用户账号',
            placeholder: '请输入用户账号',
          },
          {
            comName: 'YkcInput',
            key: 'plateNumber',
            label: '车牌号码',
            placeholder: '请输入车牌号码',
          },
          {
            comName: 'YkcInput',
            key: 'chargingOrderNo',
            label: '充电单号',
            placeholder: '请输入充电单号',
          },
          {
            comName: 'YkcInput',
            key: 'occupancyOrderNo',
            label: '占位单号',
            placeholder: '请输入占位单号',
          },
          {
            comName: 'YkcInput',
            key: 'stationName',
            label: '充电站',
            placeholder: '请选择充电站',
          },
          {
            comName: 'YkcInput',
            key: 'companyName',
            label: '归属机构',
            placeholder: '请选择归属机构',
          },
          {
            comName: 'YkcInput',
            key: 'operatorName',
            label: '归属商户',
            placeholder: '请选择归属商户',
          },
          {
            clearable: true,
            comName: 'YkcDropdown',
            key: 'orderStatus',
            label: '订单状态',
            placeholder: '请选择支付状态',
            data: localGetItem('dictionary')?.occ_order_status || [],
            configSet: {
              label: 'name',
              value: 'code',
            },
          },
        ];
      },
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('order:paceholder:refund'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.orderStatus !== '已完成' || row.refundEnable === 'false'}
                onClick={() => {
                  this.doDelete(row);
                }}>
                退款
              </ykc-button>
            ),
          },
        ];
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'occOrderExport',
          jsonNode: {
            ...this.searchParams,
          },
        });
      },
      /**
       * @desc 退款
       * */
      doDelete(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '退款金额将按原渠道返回，请确认是否退款',
          onCancel: done => {
            console.log(done);
            done();
          },
          onConfirm: done => {
            console.log(done);
            orderPage
              .occOrderRefund({ occupancyOrderId: row.occupancyOrderId })
              .then(res => {
                console.log(res);
                this.$message.success('退款成功');
                this.requestList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
        });
      },
      /**
       * 自定义列
       * */
      customColumns() {
        this.show1 = true;
      },
      /**
       * 组件change方法调用信息提示
       */
      checkboxChange(e) {
        this.customForm.checkOption = e;
        console.log('this.customForm+++', this.customForm);
      },
      /**
       *  弹窗确认
       * */
      beforeConfirm(done) {
        console.log('确认', this.customForm);
        this.$refs.customForm.validate(valid => {
          if (valid) {
            localSetItem('placeholderOrderCustomForm', JSON.stringify(this.customForm.checkOption));
            done();
            this.reload();
          }
        });
      },
      /**
       * 弹窗关闭
       * */
      onCLose() {
        this.show1 = false;
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);

        if (searchData.date && searchData.date.length > 0) {
          [this.searchParams.occEndStartTime, this.searchParams.occEndEndTime] = searchData.date;
        }

        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        console.log('清空查询', form);
        Object.assign(this.searchParams, form);
        this.searchParams.count = true;
        // 近30天初始化时间
        const startDate = getLastTime('yyyy-MM-dd', 30);
        const endDate = getLastTime('yyyy-MM-dd', 0);

        this.searchParams.date = [startDate, endDate];
        this.searchParams.occEndStartTime = startDate;
        this.searchParams.occEndEndTime = endDate;
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 请求列表数据
       */
      requestList() {
        const pageInfos = (({ current, size }) => ({ current, size }))(this.pageInfo);
        const reqParams = {
          ...pageInfos,
          ...this.searchParams,
        };
        orderPage
          .queryOccOrderList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .directConnectionPile-list {
    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }

  ::v-deep.ykc-checkbox .icon-check {
    top: 8px;
  }
  ::v-deep.customForm .el-form-item,
  ::v-deep.customForm .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
</style>
